ymaps.ready(init);

function init () {
	var map = new ymaps.Map('contactmap', {
			center: map_center,
			controls: [], //если надо убрать все лишние кнопки с карты
			zoom: map_zoom
	}),
			counter = 0;
	var Balloon = ymaps.templateLayoutFactory.createClass(map_addr);

	var placemark = new ymaps.Placemark(map_point, {
		name: 'ООО "Эскеро" tvervagon'
	}, {
		balloonContentLayout: Balloon,
		balloonPanelMaxMapArea: 0,
		 preset: 'islands#dotIcon',
		iconColor: '#1BAD03'
	});

	map.geoObjects.add(placemark);

	if(!map_scroll)
		map.behaviors.disable('scrollZoom');
	if(!map_drag)
		map.behaviors.disable('drag');
}